import axios from 'axios';
import { BASE_CONFIGS, PAGE_URLS, RESPONSE_STATUS_CODES } from 'common/constants';
import qs from 'qs';
import { AUTH_SERVICE } from 'services';

import { appInsights } from 'AppInsights';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        accept: 'application/json',
        contentType: 'application/json',
        Authorization: ''
    }
};

const fileExportOptions = {
    headers: {
        'Content-Type': 'blob',
        Authorization: ''
    },
    responseType: 'arraybuffer'
};

const _get = (url, params = {}, options = {}) => {
    return instance.get(BASE_CONFIGS.API + url, {
        ...defaultOptions,
        options,
        ...{ params },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const _export = (url, params = {}, options = {}) => {
    return instance.get(BASE_CONFIGS.API + url, {
        ...fileExportOptions,
        options,
        ...{ params },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const postExport = (url, body = {}, options = {}) =>
    instance.post(BASE_CONFIGS.API + url, body, { responseType: 'blob', options });

const post = (url, body = {}, options = {}) =>
    instance.post(BASE_CONFIGS.API + url, body, { ...defaultOptions, options });
const put = (url, body = {}, options = {}) =>
    instance.put(BASE_CONFIGS.API + url, body, { ...defaultOptions, options });
const patch = (url, body = {}, options = {}) =>
    instance.patch(BASE_CONFIGS.API + url, body, { ...defaultOptions, options });
const _delete = (url, options = {}) =>
    instance.delete(BASE_CONFIGS.API + url, { ...defaultOptions, options });

const interceptorHandleRequest = async (config) => {
    const token = await AUTH_SERVICE.getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    const errorJson = JSON.parse(JSON.stringify(error));
    switch (errorJson?.status) {
        case RESPONSE_STATUS_CODES.UNAUTHORIZED:
            window.location.href = PAGE_URLS.UNAUTHORIZED;
            return;
        case RESPONSE_STATUS_CODES.FORBIDDEN:
            window.location.href = PAGE_URLS.FORBIDDEN;
            return;
        default:
            break;
    }

    appInsights.trackException({ exception: error });

    return Promise.reject(error.response.data.errors || error.response.data.message);
};

instance.interceptors.request.use(interceptorHandleRequest, handleError);
instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { _delete as delete, _export as export, _get as get, patch, post, postExport, put };
